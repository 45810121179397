import { watchDoc } from '@happstv/shared/util/firebase/firestoreUtils'
import { AGENCY_DOC_PATH, AGENT_DOC_PATH } from '@happstv/shared/util/firebase/firestorePaths'

const initState = () => ({
  agency: undefined,
  agencyUnsubscribe: undefined,

  agencyAgent: undefined,
  agenctUserUnsubscribe: undefined,

  relevanceScoreByCreatorId: null,
  searchedTerm: null,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    reinit(state) {
      state.agencyUnsubscribe?.()
      state.agenctUserUnsubscribe?.()
      Object.assign(state, initState())
    },
    setAgency(state, agency) {
      Object.assign(state, { agency })
    },
    setAgencyUnsubscribe(state, agencyUnsubscribe) {
      Object.assign(state, { agencyUnsubscribe })
    },
    setAgencyAgent(state, agencyAgent) {
      Object.assign(state, { agencyAgent })
    },
    setAgencyAgentUnsubscribe(state, agenctUserUnsubscribe) {
      Object.assign(state, { agenctUserUnsubscribe })
    },
    SET_CREATOR_SEARCH_STATE(state, { relevanceScoreByCreatorId, searchedTerm }) {
      state.relevanceScoreByCreatorId = relevanceScoreByCreatorId
      state.searchedTerm = searchedTerm
    },
    CLEAR_CREATOR_SEARCH_STATE(state) {
      state.relevanceScoreByCreatorId = null
      state.searchedTerm = null
    },
  },

  getters: {
    agencyTabList(state) {
      const { agency, agencyAgent } = state
      if (!agency || !agencyAgent) return []

      const { isAgencyAdmin } = agencyAgent
      const { agencyType } = agency

      const isTalentAgency = ['singleRosterTalentAgency', 'multiRosterTalentAgency'].includes(agencyType)

      const list = []

      if (isTalentAgency) {
        list.push({
          targetRoute: { name: 'agencyStats' },
          title: 'Overview',
        })
      }

      if (isAgencyAdmin) {
        list.push({
          targetRoute: { name: 'agencyTeam' },
          title: 'Team',
        })
      }

      if (isTalentAgency) {
        list.push({
          targetRoute: { name: 'agencyCreators' },
          title: 'Creators',
        })

        list.push({
          targetRoute: { name: 'agencyCreatorLists' },
          title: 'Creator Lists',
        })
      }

      list.push({
        targetRoute: { name: 'agencyCampaigns' },
        title: 'Campaigns',
      })

      if (isAgencyAdmin) {
        list.push({
          targetRoute: { name: 'agencyWallet' },
          title: 'Agency Earnings',
        })
      }

      if (isAgencyAdmin) {
        list.push({
          targetRoute: { name: 'agencySettings' },
          title: 'Settings',
        })
      }

      if (!agency.agentEarningsManagedByAgency || agencyAgent.managedEarnings?.agentEarnings) {
        list.push({ divider: true })
        list.push({
          targetRoute: { name: 'agentWallet' },
          title: 'Your Earnings',
        })
      }

      return list
    },
  },

  actions: {
    init({ commit, rootState }, { agencyId }) {
      commit('reinit')

      const { signedInUserId } = rootState
      if (!signedInUserId) return

      const agencyUnsubscribe = watchDoc(AGENCY_DOC_PATH(agencyId), (agencyDoc) => {
        commit('setAgency', { id: agencyDoc.id, ...(agencyDoc.data() || {}) })
      })
      commit('setAgencyUnsubscribe', agencyUnsubscribe)

      const agencyAgentUnsubscribe = watchDoc(AGENT_DOC_PATH(agencyId, signedInUserId), (agencyAgentDoc) => {
        commit('setAgencyAgent', { id: agencyAgentDoc.id, ...(agencyAgentDoc.data() || {}) })
      })
      commit('setAgencyAgentUnsubscribe', agencyAgentUnsubscribe)
    },
  },
}
